@import "./_variables.scss";

#outer-container {
  background-color: $offWhite;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  top: 36px;
  left: 25px;

  @media (max-width: 500px) {
    left: 5px;
    width: 28px;
    height: 28px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $primary;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $secondary;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 32px;
  width: 32px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $primary;
}


/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $offWhite;
  padding: 0 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
  color: $primary;
  padding: 0 0.8em 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


.wrapper {
  max-width: 1260px;
  width: 80%;
  margin: 0 auto;
}

html {
  font-size: 125%;
  letter-spacing: 1px;
}

body {
  font-family: $paraFont;
  font-weight: normal;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
blockquote,
a,
button {
  font-family: $headingFont;
  font-weight: normal;
  margin: 0;
}


ul,
li,
a,
input,
p {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p {
  letter-spacing: 0px;
}