@import "./_variables.scss";

.about {
    height: 100vh;
    background-image: linear-gradient(transparent 75%, $offWhite), url(./assets/test_background.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .aboutText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 95px);
        text-align: center;

        .name {
            font-family: $specialFont;
            font-size: clamp(3rem, 5vw, 4rem);
            color: $primary;
        }

        .oneLiner {
            font-size: clamp(1.5rem, 3vw, 3rem);
            color: $secondary;
            padding: 15px 0 15px 0;
        }

        .introDetails {
            font-size: clamp(1rem, 2.5vw, 2rem);

            .link {
                color: $primary;
                transition: 0.25s;

                &:hover,
                &:focus {
                    color: $secondary;
                    transition: 0.25s;
                }
            }
        }

        .logos {

            .logoLink {
                transition: 0.25s;

                &:hover,
                &:focus {
                    fill: $primary;
                    transition: 0.25s;
                }

            }

            .logoimage {
                width: 50px;
                margin: 5px;

            }
        }
    }

    .arrowLink {
        text-align: center;

        .imgContainer {
            text-align: center;

            .arrow {
                height: 60px;
                width: 60px;
                animation: pulsate-bck 2s infinite;
                fill: $primary;
                transition: 0.25s;

                &:hover,
                &:focus {
                    fill: $secondary;
                    transition: 0.25s;
                }
            }
        }
    }
}


/* ----------------------------------------------
 * Generated by Animista on 2022-7-26 18:18:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes pulsate-bck {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}