@import "./_variables.scss";
@mixin headers {
    display: inline;
    font-family: $headingFont;
    text-transform: lowercase;
    font-size: clamp(2.5rem, 3.5vw, 3rem);
    margin: 0;
    width: fit-content;
    text-align: left;
    background-image: linear-gradient(360deg, transparent 10%, #fdf4b4 10%, #fdf4b4 40%, transparent 40%);
}