@import "./mixins.scss";
@import "./_variables.scss";

.contact {
  background-color: $offWhite;

  
  .contactInner {
    display: flex;
    gap: 50px;

    @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    
      }

    .contactInformation {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 25px;  

      .emailSection, .linkedInSection {
        display: flex;
        align-items: center;
        transition: 0.25s;

        &:hover,
        &:focus {
          fill: $primary;
          color: $primary;
          transition: 0.25s;

        }
        svg {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          
        }
      }
    }

    .contactForm {
      display: flex;
      flex-direction: column;
      align-items: left;
      padding-bottom: 100px;
      width: 50%;

      @media (max-width: 700px) {
          width: 100%;
      
        }
  
      .form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 25px;
  
        .input {
          display: flex;
          flex-direction: column;
  
          input {
            border: 3px solid $primary;
            padding: 15px 30px;
            transition: 0.25s;

            &:focus {
              transition: 0.25s;
              border: 3px solid $secondary;
              outline: none;
            }
          }
        }
      }
  
      .message {
        display: flex;
        flex-direction: column;
        width: 100%;
  
        textarea {
          resize: none;
          height: 200px;
          border: 3px solid $primary;
          padding: 15px 30px;
          transition: 0.25s;

          &:focus {
              border: 3px solid $secondary;
              outline: none;
              transition: 0.25s;
            }
        }
      }
  
      .submit {
        margin-top: 15px;
        padding: 20px 45px;
        width: fit-content;
        text-transform: uppercase;
        color: $offWhite;
        background-image: linear-gradient(to bottom, $primary 50%, $secondary 50%);
        transition: background-position 0.5s;
        background-size: 100% 200%;

        @media (max-width: 700px) {
            margin: 0 auto;
          
        }
  
        &:hover, &:focus {
          background-position: 0 100%;
          transition: 0.5s;
        }
      }
    }
  }
}













