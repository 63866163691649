@import "./_variables.scss";
@import "./mixins.scss";

.aboutMe {

    padding-top: 100px;

    .imageContainer {
        text-align: center;

        .profileImage {
            width: clamp(15vw, 30vh, 50vw);
            border-radius: 50%;
        }
    }


    .aboutMePara {
        font-size: 1rem;
        color: $fontPara;
        font-family: $paraFont;
        line-height: 1.4;
        text-align: center;

        p {
            padding: 10px 0;
        }
    }

}
