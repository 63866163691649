@import "./mixins.scss";
@import "./_variables.scss";

footer {
    height: 5vh;
    text-align: center;
    font-size: 1rem;
    color: $primary;
    background-color: $offWhite;
}


.projectsFull {
    background-color: $tertiary;
}