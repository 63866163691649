@import "./_variables.scss";
@import "./mixins.scss";


.heading {

    padding: 100px 0px 50px 0;

    @media (max-width: 700px){
        text-align: center;
    }
    
    .headerTitle {
            @include headers();
        }

    .zigzag {
        width: 100px;
        height: 10px;
        background-image: url("./assets/ZigZag.svg");
        background-repeat: repeat-x;

        @media (max-width: 700px) {
            margin: 0 auto;
    
        }
    }


}
