@import "./_variables.scss";
@import "./mixins.scss";

.skills {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .skillsList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 25px;

        .skillsItem {
            text-align: center;
            width: 100px;

            .logoImage {
                width: 50px;
                height: 50px;
            }
            .skillsText {
                text-align: center;

            }
        }
    }
}







