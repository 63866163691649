@import "./mixins.scss";
@import "./_variables.scss";

.projects {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 100px 0;

    @media (max-width: 800px) {
            flex-direction: column;
        }

    .arrowContainerLeft, .arrowContainerRight {
        height: fit-content;

        .arrow {
            width: 50px;
            fill: $offWhite;
            cursor: pointer;
            transition: 0.25s;


            &:hover, &:focus {
                fill: $secondary;
                transition: 0.25s;
            }
        }
    }

    .arrowContainerLeft {
        margin-right: 10px;
    }

    .arrowContainerRight {
        margin-left: 10px;
    }
}

.slide {
    opacity: 0;
    transition-duration: 1s ease;
}

.activeSlide {
    opacity: 1;
    transition-duration: 1s;
}